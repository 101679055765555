import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CloudflareSase from '../../../containers/Services/Cloudflare/SASE';

const cloudflareData = {
  title: 'Secure Access Service Edge (SASE)',
  seoParams: {
    ogImagePath: '/og-images/cloudflare-sase.png',
    ogTitle: 'Secure Access Service Edge (SASE) Services | Tech Holding',
    ogDesc: 'Streamline Network Security and Access with Tech Holding and Cloudflare.',
    ogPath: '/services/cloudflare/sase',
  },
  sections: [
    {
      heading: 'Secure Access Service Edge (SASE)',
      subheading: 'Streamline Network Security and Access',
      description: 'Our SASE solutions integrate seamlessly with your IT architecture, offering:',
      columns: [
        {
          items: [
            {
              title: 'Zero Trust Network Access (ZTNA)',
              content:
                'Provide secure and conditional access to network applications based on user identity and context.',
            },
          ],
        },
        {
          items: [
            {
              title: 'Email Security',
              content: 'Protect against phishing, malware, and other email-based threats.',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Partner with Tech Holding?',
    subheading: 'Expertise, Support, and Innovation',
    description: 'Choose Tech Holding as your Cloudflare MSSP to benefit from:',
    columns: [
      {
        items: [
          {
            title: 'Tailored Solutions',
            content: 'Custom configurations that address your specific business challenges.',
          },
          {
            title: 'Expert Support',
            content:
              'Access to our team of experts who understand your needs and are ready to assist 24/7.',
          },
        ],
      },
      {
        items: [
          {
            title: 'Strategic Growth',
            content:
              'Innovative solutions that scale with your business, helping you stay ahead in a competitive market.',
          },
        ],
      },
    ],
  },
  callToAction: {
    heading: 'Get Started with Tech Holding Today',
    description:
      'Ready to enhance your business with our Cloudflare solutions? Contact us to discuss your needs or schedule a consultation. Our experts are here to help you navigate your digital transformation journey with confidence.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const CloudflareSasePage = ({ data }) => (
  <CloudflareSase details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

CloudflareSasePage.propTypes = propTypes;

export default CloudflareSasePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
