import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../../components/Layout';
import { getSeoConfig } from '../../../../constants/defaultSeoProps';
import SaseImg from '../../../../assets/services/Sase.png';
import s from '../Cloudflare.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    seoParams: PropTypes.shape({
      ogImagePath: PropTypes.string.isRequired,
      ogTitle: PropTypes.string.isRequired,
      ogDesc: PropTypes.string.isRequired,
      ogPath: PropTypes.string.isRequired,
    }).isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            items: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
              }),
            ).isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    callToAction: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ).isRequired,
    whyPartner: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(PropTypes.string).isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string.isRequired,
              content: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const CloudflareSase = ({ details, companyQuote }) => {
  const { title, seoParams, sections } = details;
  const updatedSeo = getSeoConfig(seoParams);

  return (
    <Layout
      currentPage="/services/cloudflare/sase"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.image}>
          <img className={s.emoji} src={SaseImg} alt="" />
        </div>
        {sections.map((section) => (
          <div key={section.heading} className={s.block} data-aos="fade-up" data-aos-delay="400">
            <h3>
              <strong>{section.subheading}</strong>
            </h3>
            <h4>{section.description}</h4>
            <div className={s.flex}>
              {section.columns.map((column, colIndex) => (
                <div key={column.id || colIndex} className={s.column}>
                  <div className={s.blockDetails}>
                    <ul>
                      {column.items.map((item, itemIndex) => (
                        <li key={item.id || itemIndex}>
                          <strong>{item.title}</strong>
                          <strong>: </strong>
                          <span>{item.content}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>{details.whyPartner.heading}</h2>
          <h3>{details.whyPartner.subheading}</h3>
          <h4>{details.whyPartner.description}</h4>
          <div className={s.flex}>
            {details.whyPartner.columns.map((column) => (
              <div key={column.id} className={s.column}>
                <div className={s.blockDetails}>
                  <ul>
                    {column.items.map((item, itemIndex) => (
                      <li key={item.id || itemIndex}>
                        <strong>{item.title}</strong>
                        <strong>: </strong>
                        <span>{item.content}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h3>{details.callToAction.heading}</h3>
          <p className={s.text}>{details.callToAction.description}</p>
        </div>
      </div>
    </Layout>
  );
};

CloudflareSase.propTypes = propTypes;
export default CloudflareSase;
